
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Home() {

    const { data: session, status } = useSession()
    const loading = status === "loading"

    const router = useRouter();

    useEffect(() => {
        if (!loading && !session) {
            router.push('/auth');
        } else if (!loading && session) {
            router.push('/chat');
        }
    }, [session, loading]);

    return null;
}
